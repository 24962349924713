import { createGlobalStyle } from "styled-components";
import MuliLight from "../assets/fonts/Muli/muli300.woff2";
import MuliSemiBold from "../assets/fonts/Muli/muli600.woff2";
import MuliExtraBold from "../assets/fonts/Muli/muli800.woff2";
import PlayfairDisplayReg from "../assets/fonts/PlayfairDisplay/PlayfairDisplay400.woff2";
import PlayfairDisplayBold from "../assets/fonts/PlayfairDisplay/PlayfairDisplay700.woff2";

const Fonts = createGlobalStyle`
  @font-face {
    font-family: 'Muli';
    src: url(${MuliLight}) format('woff2');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Muli';
    src: url(${MuliSemiBold}) format('woff2');
    font-weight: 600;
    font-style: strong;
  }

  @font-face {
    font-family: 'Muli';
    src: url(${MuliExtraBold}) format('woff2');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: 'Playfair Display';
    src: url(${PlayfairDisplayReg}) format('woff2');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Playfair Display';
    src: url(${PlayfairDisplayBold}) format('woff2');
    font-weight: 700;
    font-style: strong;
  }
`;

export default Fonts;
