module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prettier-build/gatsby-browser.js'),
      options: {"plugins":[],"types":["html"],"concurrency":20,"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/opt/build/repo/node_modules/gatsby-remark-unwrap-images","id":"971fabea-81a8-5611-933a-539f23b623a8","name":"gatsby-remark-unwrap-images","version":"1.0.2","pluginOptions":{"plugins":[]},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":[]}],"root":"/opt/build/repo","extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1500,"linkImagesToOriginal":false,"withWebp":true,"showCaptions":true}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1200},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-171724862-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Daniel Bull, UX Engineer","short_name":"Daniel Bull","start_url":"/","background_color":"#fff","theme_color":"#00ffbe","display":"standalone","icon":"src/assets/images/icon.png","crossOrigin":"use-credentials","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"ef797e9771d6118d48144367e242f905"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"globPatterns":["**/*"]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
