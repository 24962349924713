/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import { MDXProvider } from "@mdx-js/react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Fonts from "./Fonts";
import Header from "./Header";
import Footer from "./Footer";
import MdxComponents from "./mdxComponents";
import GlobalStyles from "./styles/GlobalStyles";

const Layout = ({ location, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          author
        }
      }
    }
  `);

  return (
    <>
      <GlobalStyles />
      <Fonts />
      <Helmet>
        <title>{data.site.siteMetadata.title}</title>
        <link rel="shortcut icon" href="/favicon.png" type="image/png" />
      </Helmet>
      {location?.pathname !== "/" && (
        <Header siteTitle={data.site.siteMetadata.title} />
      )}

      <MDXProvider components={MdxComponents}>
        <main>{children}</main>
      </MDXProvider>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
