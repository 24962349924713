import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const Nav = () => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            menuLinks {
              name
              link
            }
          }
        }
      }
    `
  );

  const NavWrapper = styled.nav`
    ul {
      display: flex;
      list-style: none;
    }

    a {
      margin: 0 0.5rem;
      text-decoration: none;
      color: white;

      @media (max-width: 350px) {
        font-size: 1.2rem;
      }

      @media (min-width: 700px) {
        margin: 0 1rem;
      }
    }

    .active {
      color: var(--bright-green);
      text-shadow: 0 0 2px currentColor;
    }
  `;

  return (
    <NavWrapper>
      <ul>
        {site.siteMetadata.menuLinks.map(item => {
          return (
            <li key={item.name}>
              <Link
                to={item.link}
                activeClassName={item.link === "/" ? null : "active"}
                partiallyActive={true}
              >
                {item.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </NavWrapper>
  );
};

export default Nav;
