import styled from "styled-components";

const BodyHero = styled.div`
  font-size: 1.8rem;
  padding: 0.5rem 0 0.5rem 1.25rem;
  border-left: solid 1rem #00ffbe;
  margin-bottom: 4rem;

  @media (min-width: 700px) {
    font-size: 2.5rem;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

export default BodyHero;
