import React from "react";
import TwitterIcon from "../assets/images/twitter.inline.svg";
import LinkedinIcon from "../assets/images/linkedin.inline.svg";
import EmailIcon from "../assets/images/email.inline.svg";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  background-color: #f9f9f9;
  margin-top: min(10vw, 5rem);
  padding: max(5vw, 0.5rem) max(6vw, 0.5rem);
  border-top: 1px solid var(--fill-green);

  @media (min-width: 700px) {
    border-top-width: 2px;
  }

  ul {
    display: flex;
    justify-content: center;
    align-self: center;
    max-width: 960px;
    margin: 0 auto;
    list-style: none;

    > li {
      flex-grow: 1;
      text-align: center;

      a {
        display: inline-block;
        transition: transform 0.2s ease;

        &:hover {
          transform: scale(1.06);
        }
      }

      svg {
        width: min(12vw, 75px);
        fill: var(--fill-green);
      }
    }
  }
`;

const Footer = props => (
  <FooterWrapper>
    <ul>
      <li>
        <a href="https://twitter.com/dannyb1982">
          <TwitterIcon />
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/daniel-bull-9854a7a7/">
          <LinkedinIcon />
        </a>
      </li>
      <li>
        <a href="mailto:daniel.bull@me.com">
          <EmailIcon />
        </a>
      </li>
    </ul>
  </FooterWrapper>
);

export default Footer;
