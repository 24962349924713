import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import styled from "styled-components";
import Dbsq from "../assets/images/dbsq.inline.svg";
import Nav from "./Nav";

const HeaderWrapper = styled.header`
  background: var(--black);
  padding: 1rem;

  @media (min-width: 700px) {
    margin-bottom: 2rem;
  }

  > div {
    display: flex;
    max-width: 1440px;
    margin: 0 auto;
    align-items: center;

    @media (min-width: 700px) {
      padding: 0 3rem;
    }
  }

  svg {
    width: 4rem;
    padding: 0.35rem;
    border: 1px solid white;

    @media (max-width: 350px) {
      width: 3rem;
    }
  }

  h1 {
    margin: 0 0.5rem 0;
    font-size: 0;

    @media (min-width: 700px) {
      margin: 0 2rem 0;
    }
  }
`;

const Header = ({ siteTitle }) => (
  <HeaderWrapper>
    <div>
      <h1>
        <Link to="/">
          <Dbsq width="64" height="64" />
          <span className="visually-hidden">{siteTitle}</span>
        </Link>
      </h1>
      <Nav />
    </div>
  </HeaderWrapper>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
