import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  :root {
    --black: #1d1d1b;
    --grey: #717171;
    --light-grey: #a0a0a0;
    --text-green: #008666;
    --fill-green: #47a28b;
    --bright-green: #00ecb0;
    --spacing-unit: 1rem;
  }

  * {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }

  html,
  body,
  #___gatsby {
    height: 100%;

    > div > div {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }

  body {
    height: 100%;
    font-family: 'Muli', sans-serif;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.4;
    color: var(--black);
  }

  footer {
    margin-top: 2rem;
  }

  a {
    display: inline-block;
    color: var(--text-green);
  }

  figcaption {
    color: var(--grey);
    font-size: 1.2rem;
    font-style: italic;
    margin: 1.5rem 0 2.5rem;
    padding: 0 4rem 0 1.25rem;
    border-left: solid 0.25rem var(--bright-green);
  }

  #gatsby-focus-wrapper,
  #gatsby-focus-wrapper > div {
    height: 100%;
  }

  main {
    flex-grow: 1;
  }

  main * + * {
    margin-top: 1.5rem;
  }

  main ul,
  main ol {
    margin-bottom: 3rem;
    padding-left: 2rem;
  }

  iframe {
    margin-top: 2.5rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: 'Playfair Display', serif;
    font-weight: 700;
    letter-spacing: 0.05rem;
  }

  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    color: inherit;
  }

  h1 {
    font-size: 3.8rem;
    margin-bottom: 3rem;
    line-height: 1.2;
  }

  h2 {
    font-size: 2.4rem;
  }

  h3 {
    font-size: 1.6rem;
    margin-top: 2rem;
  }

  p a {
    display: inline;
  }

  .visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }
`;

export default GlobalStyles;
